<template lang="pug">
.progress
  .bar(:style="{ width: `${effectiveProgress}%`}")
</template>
<script setup lang="ts">
const props = defineProps({
  progress: {
    type: Number,
    default: 1.0
  }
})

const effectiveProgress = computed(() => Math.min(1.0, Math.max(0.0, props.progress)) * 100.0)
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.progress
  position: relative
  display: inline-block
  width: 160px
  height: 8px
  border-radius: 4px

  .bar
    height: 100%
    border-radius: 4px
    background-color: $magenta
    transition: width .33s ease-in-out

  &::before
    content: ''
    position: absolute
    top: -5px
    left: -5px
    width: calc(100% + 4px)
    height: calc(100% + 4px)
    border-radius: 12px
    border: 3px solid $beige
</style>